/* .pagination-wrapper {
    position: absolute;
    bottom: -24px;
    right: 24px;
} */

.pagination {
    display: inline-block !important;
}

.pagination li {
    cursor: pointer;
    display: inline-block;
}

.pagination li a {
    display: block;
    padding: 12px 15px 11px;
    font-size: 12px;
    font-weight: 600;
    color: #37474f;
    outline: none;
    text-decoration: none;
    border: 1px solid #f2f2f2;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.pagination li:first-child a,
.pagination li:last-child a {
    color: #37474f;
}

.pagination li:first-child a {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 0;
}

.pagination li:last-child a {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 0;
}

.pagination li a:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.pagination li.active a {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
