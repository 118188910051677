
// BORDERS

@mixin border--gray {
  border: 1px solid var(--light-gray) !important;
}

@mixin border--right-gray {
  border-right: 1px solid var(--light-gray);
}

@mixin border--bottom-gray {
  border-bottom: 1px solid var(--light-gray);
}

@mixin border--top-gray {
  border-top: 1px solid var(--light-gray);
}

// FLEX

@mixin flex__between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex__start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex__end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex__justify__between {
  display: flex;
  justify-content: space-between;
}

@mixin flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// LAYOUTS

@mixin status__pill {
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  min-height: 28px;
  min-width: 40px;
  max-width: 130px;
}

// TRANSITIONS

@mixin rot-0 {
  transform: rotate(0deg);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

@mixin rot-90 {
  transform: rotate(90deg);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

@mixin rot-180 {
  transform: rotate(180deg);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

@mixin rot-270 {
  transform: rotate(-90deg);
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

// OTHERS

@mixin value {
  display: flex;
  padding-left: 5px;
  margin-bottom: 0px;
  word-break: break-all;
  flex-wrap: wrap;
  width: 50%;
}

// OVERFLOWS

@mixin overflow-x {
  -ms-overflow-style: none;
  flex-wrap: nowrap;
  scrollbar-width: none;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin overflow-y {
  -ms-overflow-style: none;
  flex-wrap: nowrap;
  scrollbar-width: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

// CIRCLE

@mixin status--circle {
  margin: 0 0.25rem 0.25rem 0;

  circle {
    cx: 7px;
    cy: 7px;
    r: 3.5px;
  }
}
