@import "./mixins";

// PILLS

.pill--success {
  @include status__pill;
  background-color: var(--success-transparent);
  border: 1px solid var(--success-transparent);
  color: var(--success);
}

.pill--warning {
  @include status__pill;
  background-color: var(--warning-transparent);
  border: 1px solid var(--warning-transparent);
  color: var(--warning);
}

.pill--danger {
  @include status__pill;
  background-color: var(--danger-transparent);
  border: 1px solid var(--danger-transparent);
  color: var(--danger);
}

.pill--completed {
  @include status__pill;
  background-color: var(--secondary-accent-theta);
  border: 1px solid var(--secondary-accent-theta);
  color: var(--secondary);
}

.pill--disabled {
  @include status__pill;
  background-color: var(--disabled-gray);
  border: 1px solid var(--disabled-gray);
  color: var(--gray);
}

// FILTERS

.filters__container {
  position: relative;
  background-color: var(--secondary-accent-zeta);
}

.table-filter {
  background-color: var(--white);
  border: 1px solid var(--faint-gray);
  border-radius: 3px;
  // box-shadow: 0px 4px 9px #0000000a;
  height: 350px;
  position: absolute;
  right: 10px;
  top: 50px;
  width: 273px;
  z-index: 10;

  #filter-form {
    margin-bottom: 60px;
    padding-bottom: 60px !important;
    padding: 10px 20px;
    height: 280px;
    overflow-y: scroll;

    .status--success {
      @include status--circle;

      circle {
        fill: var(--success);
      }
    }

    .status--pending {
      @include status--circle;

      circle {
        fill: #f5d495;
      }
    }

    .status--failed {
      @include status--circle;

      circle {
        fill: #f18a87;
      }
    }
  }

  #filter-footer {
    bottom: 0;
    position: absolute;
    flex: 0 0 auto;
    padding: 1rem 1.5rem;
    width: 100%;
    z-index: 20;
    background: var(--white);
    @include flex__end;
    @include border--top-gray;
  }
}

.table__error {
  margin: 70px auto;
  max-width: 350px;
}

// MAIN COLORS

.primary {
  color: var(--secondary);

  &.rect {
    rect {
      fill: var(--secondary);
    }

    path {
      stroke: var(--white) !important;
    }
  }

  &.fill {
    circle,
    path {
      fill: var(--secondary);
    }
  }

  circle,
  path {
    stroke: var(--secondary);
  }
}
.primary-fill {
  color: var(--secondary);

  circle,
  path {
    fill: var(--secondary);
  }
}

.secondary {
  color: var(--secondary);

  circle,
  path {
    fill: var(--secondary);
  }
}

.circle-secondary-only circle {
  fill: var(--secondary) !important;
}

.secondary-white {
  color: var(--secondary);

  circle,
  path {
    stroke: var(--secondary);
  }

  &:hover {
    color: var(--white);

    circle,
    path {
      stroke: var(--white);
    }
  }
}


//Big Spinner
.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: var(--gray);
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.report__analysis {
	padding: 20px 0;

	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		color: var(--gray-3);
		font-size: 13px;

		span {
			color: var(--black-2);
			font-weight: 600;
		}
	}
}