@import "./mixins";

.modal {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .modal-content {
    border: 0;
    border-radius: 0px;
    box-shadow: 0px 4px 25px var(--black);
    overflow: scroll;
    height: 100%;
    z-index: 99;

    .modal-body {
      padding: 0rem 30px;

      &.no-side-paddings {
        padding: 1rem 0rem;
      }
    }

    #modal--aside-section {
      display: flex;
      padding: 30px 0 100px;
      min-height: 100%;

      @media (max-width: 991.98px) {
        padding-bottom: 4px;
        min-height: 20%;
      }
    }

    #modal--main-section {
      // padding: 20px 0 100px;
      padding: 20px 0 10px;
      height: 100%;
      padding-top: 0;

      .no-footer {
        padding: 30px 0;
      }

      @include overflow-y;
    }

    .modal-footer {
      background-color: var(--secondary-accent-zeta);
      bottom: 0;
      position: fixed;
      padding: 20px 30px;
      width: 100%;
      z-index: 4;

      @include flex__start;
    }
  }

  .without_header {
    height: calc(100% - 80px);
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    margin: 0px 0px 0px auto;
    height: 100%;

    // CENTER MODAL

    &.center-modal {
      margin: auto;

      .modal-content {
        height: auto;
        min-height: 30vh;
        max-height: 80vh;
        border-radius: 0;
        box-shadow: -8px 9px 33px 1px rgba(30, 30, 30, 0.13);
      }

      .modal-footer {
        position: absolute;
      }
    }

    &#activation-modal {
      .modal-content {
        padding: 0;

        .modal-body {
          // height: 100%;
          padding: 0;

          .modal--activation-aside {
            background-color: var(--secondary);
            padding: 30px 0 40px 0;
            flex-direction: column;

            @media (min-width: 992px) {
              // height: 100%;
              padding: 40px 0;
              width: 42%;
            }

            .normal__header {
              padding: 0 0 0 30px;
              margin: 0 0 50px;

              .title {
                display: block;
                color: var(--white);
              }

              .subtitle {
                color: var(--secondary-accent-beta);
              }
            }
          }

          .modal-footer,
          #modal--main-section {
            @media (min-width: 992px) {
              width: 58%;
            }
          }

          #modal--main-section {
            padding: 0 0 30px;
            min-height: 65%;

            @media (max-width: 991.98px) {
              height: auto;
            }

            .normal__header {
              margin: 0 0 30px;

              .title {
                font-size: 20px;
              }
            }

            // DIRECTOR FORM

            .single-director-form {
              background-color: var(--faint-gray);
              border-radius: 4px;
              margin: 0 0 15px;

              .form-header {
                align-items: center;
                color: var(--gray);
                cursor: pointer;
                display: flex;
                font-family: "gilroy-semibold";
                font-size: 18px;
                font-weight: 600;
                justify-content: space-between;
                padding: 23px 17px;

                svg path {
                  stroke: var(--gray);
                }
              }

              .form-body {
                align-items: center;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                // max-height: 750px;
                overflow: hidden;
                padding: 0px 17px 30px;
                // transition: max-height 0.5s ease-in-out;

                &.form-close {
                  max-height: 0;
                  padding: 0 17px 0;
                }
              }
            }

            // ACTIVATION COMPLETE

            #activation-complete {
              padding: 26% 8%;

              .heading-1 {
                font-size: 28px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  // PRIMARY HEADER

  .primary__header {
    padding: 20px 30px 10px;
    font-weight: bold;
    position: sticky;
    top: 0;
    background: var(--secondary-accent-theta);
    z-index: 2;

    @include flex__justify__between;

    .title {
      color: var(--black-2);
      font-family: "gilroy-semibold";
      font-size: 24px;
    }

    .subtitle {
      color: var(--gray-2);
      font-size: 12px;
      font-weight: 600;
    }
  }

  .tetiary__header {
    padding: 31px 27px 37px 53px;
    border-bottom: 1px solid var(--light-gray);
    position: sticky;
    top: 0;
    background: var(--secondary);
    z-index: 2;

    @include flex__justify__between;

    .title {
      color: var(--white);
      font-weight: 600;
      font-family: "gilroy-semibold";
      font-size: 24px;
      margin-top: 16px !important;
      margin-bottom: 7px !important;
    }

    .subtitle {
      color: var(--light-gray);
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 7px !important;
    }

    .step-pill {
      cursor: pointer;
      width: 141px;
      height: 7px;
      background-color: var(--secondary);
      border-radius: 3px;
      opacity: 0.4;

      &.active {
        opacity: 1;
        background-color: var(--secondary);
      }
    }
  }

  // NORMAL HEADER

  .normal__header {
    padding: 0 30px;
    margin: 0 0 1rem;
    font-weight: 600;

    @include flex__justify__between;

    .title {
      font-size: 28px;
    }

    .subtitle {
      color: var(--gray);
      font-size: 13px;
    }
  }
}

@media (min-width: 992px) {
  .modal-sm {
    max-width: 400px !important;
  }

  .modal-md {
    max-width: 500px !important;
  }

  .modal-lg {
    max-width: 700px !important;
  }

  .modal-xl {
    max-width: 820px !important;
  }
}

.full__light__modal {
  width: 100vw !important;
  max-width: 100vw !important;
  // background: red;

  &.trans__body {
    .modal-content {
      background: rgba(0, 0, 0, 0) !important;
    }
  }
  &.message__content {
    .modal-content {
      box-shadow: -8px 9px 33px 1px var(--black-2);
      margin: auto;
      max-width: 600px;
      height: unset !important;
      padding: 0 !important;
      border-radius: 2px;

      .modal-body {
        min-height: unset;
      }

      #modal--main-section {
        padding: 20px !important;

        header {
          font-family: "gilroy-bold";
          font-size: 2rem;
        }
        .error__msg {
          font-size: 1.5rem;
          font-weight: 600;
          padding-top: 10px;
          color: var(--gray);
        }
      }
    }
  }
}

iframe {
  z-index: 2147483647;
  background: rgba(0, 0, 0, 0.5);
  border: 0px none transparent;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease 0s;
  visibility: visible;
}
