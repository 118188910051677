// HEADINGS

.heading-1 {
  font-size: 18px;
  font-weight: 600;
}

.heading-2 {
  font-size: 16px;
  font-weight: 600;
}

.heading-3 {
  font-size: 14px;
  font-weight: 600;
}

.heading-4 {
  font-size: 12px;
  font-weight: 600;
}

.del-categ-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: var(--black-2);
  font-family: "gilroy-medium";
  span {
    color: var(--black-2);
  }
}

// TEXT SIZES

.text--xxl {
  font-size: 41px;

  @media (max-width: 991.98px) {
    font-size: 29px;
  }
}
.text--xl {
  font-size: 32px;

  @media (max-width: 991.98px) {
    font-size: 25px;
  }
}

.text--xlg {
  font-size: 28px;

  @media (max-width: 991.98px) {
    font-size: 23px;
  }
}

.text--lg {
  font-size: 24px !important;
}

.text--xslg {
  font-size: 20px !important;
}

.text--md {
  font-size: 16px !important;
}

.text--sm {
  font-size: 10px;
}

.text--secondary {
  color: var(--secondary) !important;
}

.text--primary {
  color: var(--primary) !important;
}

.text--grey {
  color: var(--gray) !important;
}
.text--grey-2 {
  color: var(--gray-2) !important;
}

.ml-auto {
  margin-left: auto;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

// FONT FAMILIES

.gilroy-regular-italic {
  font-family: "gilroy-regular-italic", sans-serif;
}

.gilroy-bold {
  font-family: "gilroy-bold", sans-serif;
}

.roboto-regular {
  font-family: "roboto-regular", sans-serif;
  font-weight: 400;
}

@media (max-width: 575.98px) {
  .text--mobile-sm {
    font-size: 10px !important;
  }
}
