
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 200ms ease-in-out;
  opacity: 1;
  height: 4rem;
  border-radius: 0;

  &:hover {
    cursor: pointer;
    color: unset;
  }
  &:focus {
    box-shadow: none;
    outline: none !important;
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.btn--xm {
    font-size: 1em;
    height: 3rem;
  }

  &.btn--sm {
    font-size: 1em;
    letter-spacing: 0.02em;
  }

  &.btn--lg {
    width: 100%;
    font-size: 1.3rem;
    letter-spacing: 0.04em;
  }
  &.btn--primary {
    background: var(--secondary);
    color: var(--white);
  }
  &.btn--danger {
    background: var(--danger-transparent);
    color: var(--danger);
  }

  &.btn--primary:hover {
    background: var(--secondary);
    color: var(--white);
  }

  &.btn--primary--bordered,
  &.btn--primary--bordered:hover {
    border: 1px solid var(--secondary);
    color: var(--secondary);
    background: unset;
  }
  &.btn--light-gray--bordered,
  &.btn--light-gray--bordered:hover {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--disabled-gray);
  }
}
