/** CONSOLAS */

@font-face {
  font-family: "consolas-regular";
  src: url("../fonts/consolas/consolas-regular.ttf");
  font-weight: 400;
}

/** GILROY */

@font-face {
  font-family: "gilroy-regular";
  src: url("../fonts/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "gilroy-bold";
  src: local("san-serif"),
    url("../fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "gilroy-semibold";
  src: local("san-serif"),
    url("../fonts/gilroy/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "gilroy-regular-italic";
  src: local("san-serif"),
    url("../fonts/gilroy/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "gilroy-heavy";
  src: local("san-serif"),
    url("../fonts/gilroy/GilroyHeavy.ttf") format("truetype");
  font-weight: 600;
}

/** ROBOTO */

@font-face {
  font-family: "roboto-regular";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}
