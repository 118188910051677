@import "./mixins";

.table-responsive {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table__wrapper {
  margin-top: 20px;
  border-top: 2px solid var(--faint-gray);
  display: flex;
  justify-content: space-between;
  max-height: 630px;
  width: 100%;

  &.full__height {
    max-height: 1000000px;
  }
  &.faint__bg {
    background: var(--disabled-gray);
    padding: 5px;
  }
  .thumb-horizontal {
    background-color: var(--danger);
    height: 10px !important;
  }

  &.table__head--gray {
    .table__head {
      background-color: var(--faint-gray) !important;
      border-radius: 2px;
      box-shadow: none !important;
    }
  }

  &.table__foot--gray {
    .table__foot {
      background-color: var(--faint-gray) !important;
      border-radius: 2px;
    }
  }

  &.table--sm {
    height: 300px;
  }

  .table {
    border-radius: 2px;
    position: relative;
    color: var(--black) !important;
    margin-bottom: 0 !important;
    width: 100%;

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: inherit;
      border-style: none;
    }

    .table__head {
      background-color: var(--white);
      //   box-shadow: 0px 1px 0px var(--light-gray);
      font-weight: 600;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;

      th {
        color: var(--black);
        font-weight: 600;
        padding: 1rem 32px !important;
        border-bottom: 0 !important;
      }

      th,
      td {
        font-size: 14px;
        vertical-align: middle !important;
      }
    }

    .table__body {
      background-color: inherit;
      td {
        color: var(--black);
        font-weight: 500;
        font-size: 14px;
      }

      tr {
        box-shadow: 0px 1px 0px var(--faint-gray);
        &:nth-child(even) {
          background-color: var(--secondary-accent-zeta);
        }
        &:hover {
          background-color: var(--faint-gray);

          .pill--success {
            border: 1px solid var(--success);
          }

          .pill--danger {
            border: 1px solid var(--danger);
          }

          .pill--warning {
            border: 1px solid var(--warning);
          }

          .btn--disabled-gray {
            border: 1px solid var(--gray);
          }
        }
      }

      img {
        max-height: 25px;
      }
    }

    .table__foot {
      background-color: inherit;

      tr {
        border-color: transparent !important;
        box-shadow: 0px 1px 0px var(--faint-gray);

        &:hover {
          background-color: var(--faint-gray);

          .pill--success {
            border: 1px solid var(--success);
          }

          .pill--danger {
            border: 1px solid var(--danger);
          }

          .pill--warning {
            border: 1px solid var(--warning);
          }
        }
      }

      td {
        color: var(--black);
        font-weight: 700;
      }
    }

    .table__body,
    .table__head,
    .table__foot {
      td {
        padding: 1rem 32px !important;
        border-top: 0 !important;
        font-size: 14px;
        vertical-align: middle;
      }

      tr {
        height: 56px;
      }
    }
  }

  .table__index {
    width: 30px;
    border: 1px solid var(--faint-gray) !important;
    padding: 0 10px;
    font-weight: 600;
    padding-top: 10px;
  }

  &.standard-table {
    .table__head {
      th {
        border-bottom: 1px solid #dee2e6 !important;
      }
      th:nth-child(2) {
        border-left: 1px solid #dee2e6 !important;
      }
    }
    .table__body {
      tr {
        td {
          border-bottom: 0 !important;
        }
        td:nth-child(2) {
          border-left: 1px solid #dee2e6 !important;
          border-bottom-width: 0px !important;
        }
      }
    }
    .table__foot {
      th,
      td {
        border-top: 1px solid #dee2e6 !important;
      }
      th,
      td {
        border-bottom: 0 !important;
      }
      th:nth-child(2),
      td:nth-child(2) {
        border-left: 1px solid #dee2e6 !important;
        border-bottom-width: 0px !important;
      }
    }
  }
  &.no--border {
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      box-shadow: none !important;
      border: none !important;
    }
  }

  &.no--inline-padding {
    .table__body,
    .table__head,
    .table__foot {
      th,
      td {
        padding: 1rem 0px !important;
      }
    }
  }
}

table > :not(:first-child) {
  border-top: 1px solid transparent !important;
}

.table--recent-activity {
  border-radius: 2px;
  // height: 400px;
  overflow: hidden;

  @include border--gray;

  .table tr {
    border-bottom: transparent;
  }
}
