:root {

  /* VARIABLE COLORS */
  --primary: #071b2b;
  --primary-gradient: linear-gradient(
    180deg,
    #071b2b 0%,
    rgba(7, 27, 43, 0.96) 100%
  );
  --secondary: rgba(91, 152, 210, 1);
  --secondary-accent-alpha: rgba(91, 152, 210, 1);
  --secondary-accent-beta: rgba(91, 152, 210, 0.7);
  --secondary-accent-gamma: rgba(91, 152, 210, 0.4);
  --secondary-accent-theta: rgba(91, 152, 210, 0.1);
  --secondary-accent-zeta: rgba(91, 152, 210, 0.05);
  --accent-gradient: linear-gradient(
    180deg,
    rgba(91, 152, 210, 0.4) 0%,
    rgba(91, 152, 210, 0) 100%
  );

  /* CONSTANT COLORS */

  /* NEUTRAL COLORS */

  --white: #ffffff;

  /* GRAYS */
  --gray: #82898f;
  --gray-2: #b2b6ba;
  --gray-3: #292d32;
  --light-gray: #e5e5e5;
  --faint-gray: #f6f8fa;
  --disabled-gray: #f5f6f6;
  --surface-gray: #ebebf5;
  --background-gray: #F3F4F4;

  /* STATUS COLORS */

  --error: #f8c9c8;
  --danger: #621a0c;
  --success: #27b636;
  --warning: #898413;
  --success-secondary: #2cc7a2;
  --success-transparent: #e0ffe8;
  --danger-transparent: #ffe0da;
  --warning-transparent: #fdfbc4;

  /* BLACK COLOURS */

  --black: #000000;
  --black-2: #1e1e1e;
}
